import React from 'react';
import { Form, FormInstance, Input, Select, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { Currency } from '@/interfaces/currency.interface';

export interface CurrencyFormProps {
  formRef: FormInstance<Currency>;
  onSubmit?: (values: Currency) => Promise<void>;
}

const CurrencyForm: React.FC<CurrencyFormProps> = ({ onSubmit, formRef }) => {
  const { t } = useTranslation();

  return (
    <Form
      form={formRef}
      name="currency-form"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmit}
    >
      <Row gutter={[10, 10]}>
        <Col span={24} sm={12} md={10}>
          <Form.Item
            label={t('currencies.name')}
            name="name"
            rules={[{ required: true, type: 'string', max: 100, whitespace: true }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={24} sm={12} md={6}>
          <Form.Item
            label={t('currencies.code')}
            name="code"
            rules={[
              {
                max: 3,
                min: 3,
                required: true,
                transform: (value: string) => value.toUpperCase(),
                type: 'string',
                whitespace: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={24} md={8}>
          <Form.Item
            label={t('general.status')}
            name="status"
            initialValue={1}
            rules={[{ required: true, message: <>{t('users.required')}</> }]}
          >
            <Select
              style={{ width: '100%' }}
              options={[
                {
                  label: t('general.active'),
                  value: 1,
                },
                {
                  label: t('general.inactive'),
                  value: 0,
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CurrencyForm;
